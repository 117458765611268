import React from 'react';
import HomeBlogs from './HomeBlogs/HomeBlogs';
import HomeCta from './HomeCta/HomeCta';
import HomeFact from './HomeFact/HomeFact';
import HomeHeroSection from './HomeHeroSection/HomeHeroSection';
import HomeOurTeam from './HomeOurTeam/HomeOurTeam';
import HomePricing from './HomePricing/HomePricing';
import HomeServices from './HomeServices/HomeServices';
import HomeAbout from './HomeAbout/HomeAbout'
import LiveHealth from './LiveHealthcare/LiveHealth';
import SignUp from './SignUpProcess/SignUp';

const Home = () => {
    return (
        <>
            <HomeHeroSection/>
            <LiveHealth />
            <HomeAbout />
            <SignUp />   
            <HomeServices/>
            <HomeOurTeam/>
            <HomeFact/>
            <HomeBlogs/>
            {/* <HomePricing/> */}
            {/* <HomeCta/> */}
        </>
    );
};

export default Home;