import { Suspense, lazy } from "react";
import { Navigate, useRoutes, useLocation } from "react-router-dom";
import MainLayout from "../layout/main";
import loader from "../Loader/pageloader.gif";
import AboutUs from "../pages/AboutUs/AboutUs";
import ModalJob from "../pages/Jobs/Modal/ModalJob";

const Loadable = (Component) => (props) => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const { pathname } = useLocation();

  return (
    <Suspense fallback={<div className="splash"><img src={loader} className="splashImage"/></div>}>
      <Component {...props} />
    </Suspense>
  );
};

export default function Routes(){
    return useRoutes([
        {
            path:"/",
            element:<MainLayout />,
            children:[
                {index:true ,element:<Home />},
                {path:'ourbrand' ,element:<Ourbrand />},
                {path:'client' ,element:<Clients />},
                {path:'Career' ,element:<Career />},
                {path:'jobs' ,element:<Jobs />},
                {path:'jobdetail/:id' ,element:<JobDetail />},
                {path:'LiveHealth' ,element:<LiveHealth />},
                {path:'about' ,element:<AboutUs />},
                {path:'modaljob' ,element:<ModalJob />},
            ],
        },
        {
            path:'*',
            element:<NotFound />    
        }

    ])
} 



const Home = Loadable(lazy(()=>import('../pages/Home/Home/Home')));
const Ourbrand = Loadable(lazy(()=>import('../pages/OurBrand/ServicesOne/ServicesOne')));
const Clients = Loadable(lazy(()=>import('../pages/Client/ClientDetails')));
const Career = Loadable(lazy(()=>import('../pages/Carreer/Carreer')));
const Jobs = Loadable(lazy(()=>import('../pages/Jobs/Jobs')));
const JobDetail = Loadable(lazy(()=>import('../pages/Jobs/JobDetail/JobDetail')));
const LiveHealth = Loadable(lazy(()=>import('../pages/Home/Home/LiveHealthcare/LiveHealth')));




const NotFound = Loadable(lazy(()=>import('../pages/NotFound/NotFound')));