import React from "react";
import { Link } from "react-router-dom";
import healthcare from '../HomeAbout/healthcare.jpg';

const HomeFiveAbout = () => {
  const aboutData = [
    {
      id: 1,
      themeBg: "theme-bg",
      bgIcon: "services_bg_icon1.png",
      img: "h5services__icon1.png",
      btnText: "About us",
      url: "/",
      heading: "About us",
      content:
        "McMillan  Healthcare specialise in sourcing and recruiting the highest calibre of healthcare professionals for semi permanent, contract and temporary positions in both the NHS and the private healthcare sector all over the U.K.",
    },
    {
      id: 2,
      themeBg: "theme-bg2",
      bgIcon: "services_bg_icon2.png",
      img: "h5services__icon2.png",
      btnText: "find",
      url: "/jobs",
      heading: "Search Jobs",
      content:
        "Mental Health Nurses Urgently required in the Hampshire area Days, nights and weekends available High rates of pay Contact for further details General and A&E nurses urgently required Days and night available Devon area Excellent rates of pay",
    },
    {
      id: 2,
      themeBg: "pink-bg",
      bgIcon: "services_bg_icon3.png",
      img: "h5services__icon2.png",
      btnText: "More",
      url: "/jobs",
      heading: "Testimonials",
      content:
        "I have worked for other agencies in the past and I would say McMillan healthcare are the most professional agency that I have had the pleasure of working with. I would not hesitate to recommend them to anyone looking McMillan healthcare  ",
    },
  ];
  return (
    <>
      <section className="about-area pt-130 pb-100">
        <div className="container">
          <div className="row">
            <div className="col-lg-10 offset-lg-1">
              <div className="about-title text-center mb-60">
                <h5 className="blue-color">
                  McMillan Health Care help increase your readers.
                </h5>
                <h1>5+ Years Of Since We Provide Medical Serving</h1>
              </div>
            </div>
          </div>
          <div className="row g-0">
            {aboutData.map((about, index) => {
              const { themeBg, bgIcon, img, btnText, url, heading, content } =
                about;
              return (
                <div key={index} className="col-lg-4 mb-30">
                  <div className={`h5services-wrapper ${themeBg}`}>
                    <i className="h5sicon-bg">
                      <img src={`img/icon/${bgIcon}`} alt="icon" />
                    </i>
                    <div className="h5services-content">
                      <i className="h5services-icon">
                        <img src={`img/icon/${img}`} alt="" />
                      </i>
                      <h3 className="white-color h5services-title">
                        {heading}
                      </h3>
                      <p>{content}</p>
                      <Link
                        to={url}
                        className="green-color text-uppercase f-500"
                      >
                        <span className="plus">+</span>
                        <span className="link">{btnText}</span>
                      </Link>
                    </div>
                  </div>
                </div>
              );
            })}
            {/* //newDiv */}
          </div>
        </div>
      </section>
      <section>
        <div className="container">
          <div className="row">
            <div className="col-md-6">
              <h2>ALL ABOUT</h2>
              <h2 className="text-primary">MCMILLAN HEALTHCARE</h2>
              <p>
                MCMILLAN HEALTHCARE is a leading supplier of healthcare staffing
                in the United Kingdom, supplying specialist staff for temporary,
                part-time and permanent positions throughout the healthcare
                sector.<br /><br/> Our mission statement is simple: <br /><br/> <b>“Providing Outstanding
                Services To The Healthcare Sector” </b> <br/><br/>We have built our
                fast-growing reputation by delivering a compliant, efficient,
                reliable and professional service to all of our respected
                clients. As an approved framework provider, MCMILLAN HEALTHCARE
                is known to the sector as a trusted supplier of NHS staffing
                solutions. <br/><br/><span className="my-4">Our dedicated team are proud to support a diverse
                range of healthcare sectors 24/7 with our amazing candidates, it
                really does mean the world to us.</span> <br/><br/>Contact one of our team today
                on 0207 407 6620 to find out how we can provide support with
                your staffing requirements.
              </p>
            </div>
            <div className="col-md-6">
              <img src={healthcare} alt=""  style={{height: "80vh"}}/>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default HomeFiveAbout;
