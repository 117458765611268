import React, { useState } from 'react'
import "../../../App.css";
import axios from "axios";
import Loader from "../../../Loader/mcmloader.gif";
import Swal from 'sweetalert2';

const Search = ({searchApi, setName, setLocation}) => {
  return (
    <>
        <section className='py-3'>
            <div className='container'>
                <div className='row d-flex justify-content-center align-item-center py-3'>
                    <div className='col-md-3 m-2'>
                        <input className='searchbar' type="search" placeholder='Job Title' onChange={(e)=>setName(e.target.value)}/>
                    </div>
                    <div className='col-md-3 m-2'>
                        <input className='searchbar' type="search" placeholder='Location' onChange={(e)=>setLocation(e.target.value)}/>
                    </div>
                    <div className='col-md-2 m-2'>
                        <button className='searchbtn' onClick={searchApi}>Search</button>
                    </div>
                </div>
            </div>
        </section>
    </>
  )
}

export default Search