import { BrowserRouter } from "react-router-dom";
import ScrollTop from "./components/ScrollTop";
import AllContext from "./context/AllContext";
import AboutUs from "./pages/AboutUs/AboutUs";
import Appointment from "./pages/Appointment/Appointment";
import BlogDetails from "./pages/BlogDetails/BlogDetails";
import BlogLeftSideBar from "./pages/BlogLeftSideBar/BlogLeftSideBar";
import BlogNoSideBar from "./pages/BlogNoSideBar/BlogNoSideBar";
import Blogs from "./pages/Blogs/Blogs";
import BlogThreeColMasonry from "./pages/BlogThreeColMasonry/BlogThreeColMasonry";
import BlogThreeColumn from "./pages/BlogThreeColumn/BlogThreeColumn";
import BlogTwoColumn from "./pages/BlogTwoColumn/BlogTwoColumn";
import BlogTwoColumnMasonry from "./pages/BlogTwoColumnMasonry/BlogTwoColumnMasonry";
import CheckoutPage from "./pages/CheckoutPage/CheckoutPage";
import Contact from "./pages/Contact/Contact";
import DetailsAudio from "./pages/DetailsAudio/DetailsAudio";
import DetailsGallery from "./pages/DetailsGallery/DetailsGallery";
import DetailsLeftSideBar from "./pages/DetailsLeftSideBar/DetailsLeftSideBar";
import DetailsVideo from "./pages/DetailsVideo/DetailsVideo";
import DoctorDetails from "./pages/Doctors/DoctorDetails/DoctorDetails";
import DoctorsOne from "./pages/Doctors/DoctorsOne/DoctorsOne";
import DoctorsTwo from "./pages/Doctors/DoctorsTwo/DoctorsTwo";
import Home from "./pages/Home/Home/Home";
import HomeThree from "./pages/HomeThree/HomeThree";
import HomeTwo from "./pages/HomeTwo/HomeTwo";
import HomeFour from "./pages/HomeFour/HomeFour";
import Login from "./pages/Login/Login";
import PortfolioSlider from "./pages/PortfolioSlider/PortfolioSlider";
import PortfolioThreeCol from "./pages/PortfolioThreeCol/PortfolioThreeCol";
import PortfolioTwoColumn from "./pages/PortfolioTwoColumn/PortfolioTwoColumn";
import Register from "./pages/Register/Register";
import ServicesDetails from "./pages/Services/ServicesDetails/ServicesDetails";
import ServicesOne from "./pages/Services/ServicesOne/ServicesOne";
import ServicesTwo from "./pages/Services/ServicesTwo/ServicesTwo";
import ShopDetails from "./pages/ShopDetails/ShopDetails/ShopDetails";
import ShopPage from "./pages/ShopPage/ShopPage/ShopPage";
import ShoppingCart from "./pages/ShoppingCart/ShoppingCart";
import WishList from "./pages/wishList/WishList";
import HomeFive from "./pages/HomeFive/HomeFive";
import "./App.css";
import NotFound from "./pages/NotFound/NotFound";
import Routes from "./routes";
import { useRef, useState } from "react";
import loader from "./Loader/pageloader.gif";

function App() {
  const [isSplash, setIsSplash] = useState(true);
  const [css, setCss] = useState(false);
  const videoRef = useRef(null);

  if (videoRef.current) {
    videoRef.current.play();
  }

  setTimeout(() => {
    setCss(true);
  }, 3200);

  setTimeout(() => {
    setIsSplash(false);
  }, 6000);

  return (
    <>
      {isSplash ? (
        <>
          <div className="splash mydiv bgnurse">
            <img
              alt=""
              src={loader}
              className={`${css ? "cssanimation sequence fadeOutLeft" : ""}`}
            />
          </div>
        </>
      ) : (
        <AllContext>
          <BrowserRouter>
            <ScrollTop />
            <Routes />
          </BrowserRouter>
        </AllContext>
      )}
    </>
  );
}

export default App;
