import React from "react";
import step1 from "../SignUpProcess/Icon-1-1 (1).png";
import step2 from "../SignUpProcess/Icon-2-1 (1).png";
import step3 from "../SignUpProcess/Icon-3-1 (1).png";
import step4 from "../SignUpProcess/Icon-4 (2).png";

const SignUp = () => {
  return (
    <>
      <section className="my-5">
        <div className="container">
          <h2 className="text-center">OUR SIGN UP PROCESS</h2>
          <div className="row mt-5">
            <div className="col-md-3">
              <div className="d-flex justify-content-center align-item-center">
                <img src={step1} alt="" className="w-50" />
              </div>
              <h4 className="text-center text-primary my-4 ">STEP 1</h4>
              <h2 className="text-center">PRE SCREEN</h2>
              <p className="text-center">
                To begin your journey with MCMILLAN HEALTHCARE, we start off we
                with a pre-screening registration call with a member of our
                team.
              </p>
            </div>
            <div className="col-md-3">
              <div className="d-flex justify-content-center align-item-center">
                <img src={step2} alt="" className="w-50" />
              </div>
              <h4 className="text-center text-primary my-4 ">STEP 2</h4>
              <h2 className="text-center">REGISTER</h2>
              <p className="text-center">
                Our registration process includes the documentation of our
                internal registration forms and compliance documents.
              </p>
            </div>
            <div className="col-md-3">
              <div className="d-flex justify-content-center align-item-center">
                <img src={step3} alt="" className="w-50" />
              </div>
              <h4 className="text-center text-primary my-4 ">STEP 3</h4>
              <h2 className="text-center">CONSULT</h2>
              <p className="text-center">
                Once all of our compliance checks and documents are filed, we
                start to find the best position for you and your experience.
              </p>
            </div>
            <div className="col-md-3">
              <div className="d-flex justify-content-center align-item-center">
                <img src={step4} alt="" className="w-50" />
              </div>
              <h4 className="text-center text-primary my-4 ">STEP 4</h4>
              <h2 className="text-center">PLACEMENT</h2>
              <p className="text-center">
                The process is now complete and you are a fully registered
                member of the MCMILLAN HEALTHCARE workforce, welcome to the
                team!
              </p>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default SignUp;
