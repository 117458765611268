import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import Modal from 'react-bootstrap/Modal';
import VideoPopup from '../../../../components/VideoPopup/VideoPopup';
import useGlobalContext from '../../../../hooks/useGlobalContext';

const HomeTwoHeroSection = () => {
   const [show, setShow] = useState(false);
   const handleClose = () => setShow(false);
   const handleShow = () => setShow(true);

   const values = useGlobalContext();
   const { setIsOpen } = values;
   return (
      <>
         {/* <VideoPopup videoId="oU_GUAWz52w" /> */}

         <section className="hero-area" >
            <div className="hero-slider hero_two_slider">
               <div className="slider-active">
                  <div className="single-slider slider-height slider-height-2 d-flex align-items-center"
                     data-background="img/slider/slider-bg-2.jpg">
                     <div className="container">
                        <div className="row align-items-center">
                           <div className="col-xl-6 col-lg-6 col-md-10">
                              <div className="hero-text hero-text-2 pt-35">
                                 <div className="hero-slider-caption hero-slider-caption-2 mb-1 0">
                                    <h5 className="white-color">We are here for your care.</h5>
                                    <h1 className="white-color" style={{ fontSize: "75px" }} >Providing the highest quality nurses and care workers</h1>
                                 </div>
                                 <div className="hero-slider-btn" style={{ marginBottom: "400px" }}>
                                    <Link to="/about" className="primary_btn btn-icon btn-icon-blue ml-0"><span>+</span>about us</Link>
                                    <button onClick={() => setIsOpen(true)} className="play-btn popup-video"><i className="fas fa-play"></i></button>
                                 </div>
                              </div>
                           </div>
                           <div className="col-xl-5 offset-xl-1 col-lg-6 col-md-12">
                              <div className="slider-right-2">
                                 <div className="hero-slider-btn">
                                    <Link to="#" className="primary_btn btn-icon btn-icon-blue ml-0" onClick={handleShow}><span>+</span>Register with us</Link>
                                    <Modal show={show} onHide={handleClose}>
                                       <Modal.Body>
                                          <Form>
                                             <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                                                <Form.Label>Name</Form.Label>
                                                <Form.Control
                                                   type="name"
                                                   placeholder="Enter Your Name"
                                                   autoFocus
                                                />  
                                                <Form.Label>Telephone</Form.Label>
                                                <Form.Control
                                                   type="telephone"
                                                   placeholder="Enter Your Telephone Number "
                                                   autoFocus
                                                />  
                                                <Form.Label>Email address</Form.Label>
                                                <Form.Control
                                                   type="email"
                                                   placeholder="Enter Your Email"
                                                   autoFocus
                                                />  
                                             </Form.Group>
                                            
                                          </Form>
                                       </Modal.Body>
                                       <Modal.Footer>
                                          <Button variant="secondary" onClick={handleClose}>
                                          Submit
                                          </Button>
                                          <Button variant="primary" onClick={handleClose}>
                                             Save Changes
                                          </Button>
                                       </Modal.Footer>
                                    </Modal>
                                 </div>
                              </div>
                           </div>
                        </div>
                     </div>
                  </div>
               </div>
            </div>
         </section>
      </>
   );
};

export default HomeTwoHeroSection;