import React, { useState } from 'react'
import CommonPageHeader from '../../components/CommonPageHeader/CommonPageHeader'
import AllJobs from './AllJobs/AllJobs'
import Search from './JobsSearch/Search'
import axios from "axios";
import Loader from "../../Loader/mcmloader.gif";
import Swal from 'sweetalert2';

const Jobs = () => {

    const [name, setName] = useState("");
    const [location, setLocation] = useState("");
    const [Searchapi, setSearchApi] = useState([]);
    const [loader, setLoader] = useState(false);
    // const [Status, setStatus] = useState();

    const searchApi = () => {
        setLoader(true);
        const data = new FormData();
        data.append("name", name);
        data.append("Location", location);
        var config = {
          method: "POST",
          url: "https://mcmilanheathcareapi.dev-sh.xyz/api/jobs/search",
          data: data,
          headers: {
            Accept: "application/json",
            // Authorization: `Bearer ${token}`,
          },
        };
    
        axios(config)
          .then((response) => {
            setSearchApi(response?.data?.job, "api");
            console.log(response, "api");
    
            if (response.data.status === true) {
              setLoader(false);
            } else {
              setLoader(false);
             <h2>No Result Found</h2>
            }
          })
          .catch(function (error) {
            // setError(error?.response?.data?.errors);
            // setLoader(false);
          });
      };
  return (
    <>
    <CommonPageHeader title="Find Jobs" subtitle="Jobs"/>
    <Search searchApi={searchApi} setName={setName} setLocation={setLocation}/>
          <AllJobs myData={Searchapi} />
    </>
  )
}

export default Jobs