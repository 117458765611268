import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import axios from "axios";

const AllJobs = ({myData}) => {
    const [api, setApi] = useState([]);
    const [loader, setLoader] = useState(false);

    const navigate = useNavigate();
    const JOBS = () => {
      setLoader(true);
  
    //   const token = localStorage.getItem("accesstoken");
      var config = {
        method: "get",
        url: 'https://mcmilanheathcareapi.dev-sh.xyz/api/jobs',
        headers: {
          Accept: "application/json",
        //   Authorization: `Bearer ${token}`,
        },
      };
  
      axios(config)
        .then(function (response) {
          setApi(response?.data?.job);
          setLoader(false);
        })
        .catch(function (error) {
          setLoader(false);
  
          console.log(error);
        });
    };
    console.log("JOBS ", api);
  
    useEffect(() => {
      JOBS();
    }, []);
    useEffect(()=>{
        setApi(myData)
    },[myData])
  return (
    <>
    {loader == true ? "Loading....." :
      <section className="py-5">
        <div className="container-fluid">
          <div className="row d-flex justify-content-center">
            {api?.map((e)=>(
            <div className="col-md-3 px-3 m-3 jobcard">
            <div onClick={()=>navigate('/jobdetail/' + e.id)}>
              <div className=" p-2">
                <h6 className="display-6 fs-5 fw-bolder">
                  {e.name}
                </h6>
                <h6 className="text-primary">{e.location}</h6>
              </div>
              <div className="p-2">
                <h6 className="display-6 fs-5 fw-bolder">Salary</h6>
                <h5 className="text-primary">${e.salary}</h5>
              </div>
              <div className="p-2 ">
                <p className="text-dark fs-6"> {e.desc}</p>
              </div>
            </div>
              <div className="py-2 m-2 d-flex justify-content-center">
              <button className="btn theme-btn" onClick={()=>navigate('/jobdetail/' + e.id)}>Read More</button>
              </div>
            </div>
            ))}
            {api.length === 0 && <h2>No result Found </h2>}
         
            
          </div>
        </div>
      </section>
}
    </>
  );
};

export default AllJobs;
