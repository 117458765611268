import React, { useState } from "react";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import Modal from "react-bootstrap/Modal";
import { useNavigate, useParams } from "react-router-dom";
import Swal from "sweetalert2";
import axios from "axios";
import Loader from "../../../Loader/pageloader.gif";

const QuickRegisteration = ({ setModal, id }) => {
  const [show, setShow] = useState(true);
  const [First, setFirstName] = useState("");
  const [Last, setLastName] = useState("");
  const [Email, setEmail] = useState("");
  const [Number, setNumber] = useState("");
  const [Location, setLocation] = useState("");
  const [Pin, setPin] = useState("");
  const [cv, setCV] = useState();
  // const [id, setCondition] = useState()
  const [error, setError] = useState();
  const [loader, setLoader] = useState(false);

  const handleClose = () => {
    setShow(false);
    setModal(false);
  };

  const handleShow = () => setShow(true);
  const navigate = useNavigate();
  const addapi = () => {
    setLoader(true);
    const data = new FormData();
    data.append("first_name", First);
    data.append("last_name", Last);
    data.append("telephone", Number);
    data.append("email", Email);
    data.append("location", Location);
    data.append("pin", Pin);
    data.append("cv", cv);
    data.append("id", id);

    var config = {
      method: "POST",
      url: "https://mcmilanheathcareapi.dev-sh.xyz/api/jobs/mail/send",
      data: data,
      headers: {
        Accept: "application/json",
        // Authorization: `Bearer ${token}`,
      },
    };

    axios(config)
      .then((response) => {
        console.log(response, "api");

        if (response.data.status === true) {
          setLoader(false);
          Swal.fire({
            title: "Good Job ! ",
            text: response?.data?.message,
            icon: "success",
            button: "Ok",
          });
          setModal(false);
        } else {
          setLoader(false);
          Swal.fire({
            title: "OPPS! ",
            text: "Something Went Wrong",
            icon: "Danger",
            button: "Ok",
          });
        }
      })
      .catch(function (error) {
        setError(error?.response?.data?.errors);
        setLoader(false);
      });
  };
  console.log(error, "error");

  return (
    <>
      <Modal show={show} onHide={handleClose}>
        {loader === true ? (
          <img src={Loader} className="loaderimg" />
        ) : (
          <>
            <Modal.Header>
              <Modal.Title>Fill Your Details</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <div className="row">
                <div className="col-md-6">
                  <Form.Label>First Name</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="John"
                    autoFocus
                    onChange={(e) => setFirstName(e.target.value)}
                  />
                </div>

                <div className="col-md-6">
                  <Form.Label>Last Name</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="smith"
                    autoFocus
                    onChange={(e) => setLastName(e.target.value)}
                  />
                </div>
                <div className="col-md-6">
                  <Form.Label>Email address</Form.Label>
                  <Form.Control
                    type="email"
                    placeholder="name@example.com"
                    autoFocus
                    onChange={(e) => setEmail(e.target.value)}
                  />
                </div>
                <div className="col-md-6">
                  <Form.Label>Phone Number</Form.Label>
                  <Form.Control
                    type="number"
                    placeholder="098765432"
                    autoFocus
                    onChange={(e) => setNumber(e.target.value)}
                  />
                </div>
                
                {/* <div className="col-md-6">
                  <Form.Label>Location</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Your Location"
                    autoFocus
                    onChange={(e) => setLocation(e.target.value)}
                  />
                </div>
                <div className="col-md-6">
                  <Form.Label>NMC Pin</Form.Label>
                  <Form.Control
                    type="password"
                    placeholder="Do You Have NMC Pin or HPC"
                    autoFocus
                    onChange={(e) => setPin(e.target.value)}
                  />
                </div> */}
              </div>
              {/* <div className="">
                <label className="form-label">Upload CV</label>
                <input
                  type="file"
                  className="form-control"
                  onChange={(e) => setCV(e.target.files[0])}
                />
              </div> */}
              <div className="pt-3">
                <input
                  type="checkbox"
                  required
                  // onChange={(e)=>setCondition(e.target.value)}
                />
                <span className="ms-2">Agree & countinue</span>
              </div>
            </Modal.Body>
            <Modal.Footer>
              <Button variant="secondary" onClick={handleClose}>
                Close
              </Button>
              <Button variant="primary" onClick={addapi}>
                Save Changes
              </Button>
            </Modal.Footer>
          </>
        )}
      </Modal>
    </>
  );
};

export default QuickRegisteration;
