import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import Sidebar from '../Sidebar/Sidebar';
import useGlobalContext from '../../../hooks/useGlobalContext';
import ModalJob from '../../../pages/Jobs/Modal/ModalJob';
import UploadYourCv from '../../../pages/Jobs/Modal/UploadYourCv';
import QuickRegisteration from '../../../pages/Jobs/Modal/QuickRegisteration';

const HomeHeader = () => {
   const [show, setShow] = useState(false);
   const handleClose = () => setShow(false);
   const [modal, setModal] = useState(false);
   const [quick, setQuick] = useState(false);
   const handleShow = () => setShow(true);

   const openModal = () => {
      setModal(true);
    };

   const quickreg = () => {
      setQuick(true);
    };
  
    console.log(modal, "modal")

   const { stickyMenu } = useGlobalContext();
   return (
      <>
         <header>
            <div className="top-bar d-none d-md-block">
               <div className="container">
                  <div className="row d-flex align-items-center">
                     <div className="col-xl-6 offset-xl-1 col-lg-6 offset-lg-1 col-md-7 offset-md-1">
                        <div className="header-info">
                           <span><i className="fas fa-phone"></i>0203 9255590</span>
                           <Link style={{color: "#647589"}} to="www.mcmillanhealthcare.com" target='_black'><i style={{color: "#647589"}} className="fas fa-envelope"></i> &nbsp; www.mcmillanhealthcare.com</Link>
                        </div>
                     </div>
                     <div className="col-xl-5 col-lg-5 col-md-4">
                        <div className="header-top-right-btn f-right">
                           <Link to="/contact" className="primary_btn">Make Appointment</Link>
                        </div>
                     </div>
                  </div>
               </div>
            </div>

            <div className={stickyMenu ? "header-menu-area sticky_menu" : "header-menu-area"}>
               <div className="container menu_wrapper">
                  <div className="row align-items-center">
                     <div className="col-xl-3 col-lg-3 col-md-6 col-6 d-flex align-items-center " style={{maxWidth:"100px"}}>
                        <div className="logo logo-circle pos-rel">
                           <Link to="/"><img src="img/logo/logo.png" alt="" /></Link>
                        </div>
                     </div>
                     <div className="col-xl-9 col-lg-9 col-md-6 col-6">
                        <div className="header-right f-right">
                           {/* <div className="header-lang f-right pos-rel d-none d-lg-block">
                              <div className="lang-icon">
                                 <img src="img/icon/lang.png" alt=""/>
                                    <span>EN<i className="fas fa-angle-down"></i></span>
                              </div>
                              <ul className="header-lang-list">
                              https://www.facebook.com/ <li><a href="#">USA</a></li>
                                 <li><a href="#">UK</a></li>
                                 <li><a href="#">CA</a></li>
                                 <li><a href="#">AU</a></li>
                              </ul>
                           </div> */}
                           <div className="header-social-icons f-right d-none d-xl-block">
                              <ul>
                                 <li><a href="https://www.facebook.com/" target="_blank"><i className="fab fa-facebook-f"></i></a></li>
                                 <li><a href="https://twitter.com/mcmillanhealthc" target="_blank"><i className="fab fa-twitter"></i></a></li>
                                 <li><a href="https://www.instagram.com/mcmillanhealthcare/" target="_blank"><i className="fab fa-instagram"></i></a></li>
                                 {/* <li><a href="https://www.tiktok.com/@mcmillanhealthc" target="_blank"><i className="fab fa-tiktok"></i></a></li> */}
                                 <li><a href="https://www.snapchat.com/" target="_blank"><i className="fab fa-snapchat" ></i></a></li>
                              </ul>
                           </div>
                        </div>
                        <div className="header__menu f-right">
                           <nav id="mobile-menu">
                              <ul>
                                 <li><Link to="/">Home </Link>
                                 
                                 </li>
                                 {/* <li><Link to="/ourbrand">Our Brand</Link>
                                  
                                 </li> */}
                                 <li><Link to="/client">Client</Link>
                                  
                                 </li>
                                 <li><Link to="/career">Carreer</Link>
                                 
                                 </li>
                                 <li><Link to="/jobs">Jobs</Link>
                                 </li>
                                 <li><Link to="/">Candidates</Link>
                                    <ul className="submenu">
                                       <li><Link to="/jobs">Quick Job Search</Link></li>
                                       <li><Link onClick={openModal} to="#">Upload your C.V</Link></li>
                                       <li><Link onClick={quickreg} to="#">Quick Registration</Link></li>
                                    </ul>
                                 </li>
                                 <li><Link to="/about">About McMillan Healthcare</Link>
                             
                                 </li>
                               
                             
                              </ul>
                           </nav>
                        </div>

                        <div className="side-menu-icon d-lg-none text-end">
                           <button onClick={handleShow} className="side-toggle border-0 bg-transparent">
                              <i className="fas fa-bars"></i> </button>
                        </div>
                     </div>
                  </div>
               </div>
            </div>
         </header>

         <Sidebar show={show} handleClose={handleClose} />
         {modal ? <UploadYourCv setModal={setModal}/> : null}
         {quick ? <QuickRegisteration setModal={setQuick}/> : null}
      </>
   );
};

export default HomeHeader;