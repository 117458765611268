import axios from "axios";
import React, { useState } from "react";
import Swal from "sweetalert2";
import AllJobs from "../../../Jobs/AllJobs/AllJobs";
import Jobs from "../../../Jobs/Jobs";
import Search from "../../../Jobs/JobsSearch/Search";

const LiveHealth = () => {
  const [name, setName] = useState("");
  const [location, setLocation] = useState("");
  const [Searchapi, setSearchApi] = useState([]);
  const [loader, setLoader] = useState(false);

  const searchApi = () => {
    setLoader(true);
    const data = new FormData();
    data.append("name", name);
    data.append("Location", location);
    var config = {
      method: "POST",
      url: "https://mcmilanheathcareapi.dev-sh.xyz/api/jobs/search",
      data: data,
      headers: {
        Accept: "application/json",
        // Authorization: `Bearer ${token}`,
      },
    };

    axios(config)
      .then((response) => {
        setSearchApi(response?.data?.job);
        // setStatus(response?.data?.status);
        console.log(response, "api");

        if (response.data.status === true) {
          setLoader(false);
        } else {
          setLoader(false);
        }
      })
      .catch(function (error) {
        // setError(error?.response?.data?.errors);
        // setLoader(false);
      });
  };
  console.log(Searchapi,"Searchapi")

  return (
    <>
      <section>
        <div className="container">
          <div className="heading">
            <h2 className="text-center">LIVE HEALTHCARE POSITIONS</h2>
          </div>
          {/* <Jobs /> */}
          <Search
            searchApi={searchApi}
            setName={setName}
            setLocation={setLocation}
          />
          <AllJobs myData={Searchapi} />
          {/* {Searchapi.length === 0 && <h2>No Jobs found</h2>} */}
        </div>
      </section>
    </>
  );
};

export default LiveHealth;
