import React from "react";
import Slider from "react-slick";

const AboutTestimonial = () => {
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
  };
  return (
    <>
      <div
        className="testimonials-area pt-115 pb-120 "
        style={{ marginBottom: "190px"}}
      >
        <div className="container">
          <div className="section-title text-center pos-rel mb-40">
            <div className="section-icon">
              <img
                className="section-back-icon"
                src="img/section/section-back-icon.png"
                alt=""
              />
            </div>
            <div className="section-text pos-rel">
              <h5>Testimonials</h5>
              <h1>
                Our Clients Says <br /> About Us
              </h1>
            </div>
            <div className="section-line pos-rel">
              <img src="img/shape/section-title-line.png" alt="" />
            </div>
          </div>

          <Slider {...settings}>
            <div>
              <div className="testimonials-area ">
                <div className="container">
                  <div className="row">
                    <div className="col-xl-6 offset-xl-3 col-lg-8 offset-lg-2 col-md-10 offset-md-1"></div>
                  </div>
                  <div className="single-testi">
                    <div className="row">
                      <div className="col-xl-10 offset-xl-1 col-lg-12 col-md-12">
                        <div className="testi-box text-center pos-rel">
                          <div className="testi-content pos-rel">
                            <div className="testi-bg-icon">
                              <img
                                src="img/testimonials/testi-box-bg.png"
                                alt=""
                              />
                            </div>
                            <div className="testi-quato-icon">
                              <img
                                src="img/testimonials/testi-quato-icon.png"
                                alt=""
                              />
                            </div>
                            <div className="text-text-boxx">
                              <p>
                              “McMillan healthcare have been amazing, always on hand to speak to and found me work very quickly! Wouldn’t hesitate to recommend them”
                              </p>
                            </div>
                            {/* <span></span> */}
                          </div>
                          {/* <div className="testi-author">
                            <h2 className="testi-author-title">
                              Rosalina D. Williamson
                            </h2>
                            <span className="testi-author-desination">
                              founder, uithemes
                            </span>
                          </div>
                          <div className="test-author-icon">
                            <img
                              src="img/testimonials/testi-author-icon.png"
                              alt=""
                            />
                          </div> */}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div>
              <div className="testimonials-area ">
                <div className="container">
                  <div className="row">
                    <div className="col-xl-6 offset-xl-3 col-lg-8 offset-lg-2 col-md-10 offset-md-1"></div>
                  </div>
                  <div className="single-testi">
                    <div className="row">
                      <div className="col-xl-10 offset-xl-1 col-lg-12 col-md-12">
                        <div className="testi-box text-center pos-rel">
                          <div className="testi-content pos-rel">
                            <div className="testi-bg-icon">
                              <img
                                src="img/testimonials/testi-box-bg.png"
                                alt=""
                              />
                            </div>
                            <div className="testi-quato-icon">
                              <img
                                src="img/testimonials/testi-quato-icon.png"
                                alt=""
                              />
                            </div>
                            <div className="text-text-boxx">
                              <p>
                              “I registered with McMillan  and the process from start to finish has been really quick and Josh my consultant was there to help and talked me through everything!  Great company”
                              </p>
                            </div>
                            {/* <span></span> */}
                          </div>
                          {/* <div className="testi-author">
                            <h2 className="testi-author-title">
                              Rosalina D. Williamson
                            </h2>
                            <span className="testi-author-desination">
                              founder, uithemes
                            </span>
                          </div>
                          <div className="test-author-icon">
                            <img
                              src="img/testimonials/testi-author-icon.png"
                              alt=""
                            />
                          </div> */}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div>
              <div className="testimonials-area ">
                <div className="container">
                  <div className="row">
                    <div className="col-xl-6 offset-xl-3 col-lg-8 offset-lg-2 col-md-10 offset-md-1"></div>
                  </div>
                  <div className="single-testi">
                    <div className="row">
                      <div className="col-xl-10 offset-xl-1 col-lg-12 col-md-12">
                        <div className="testi-box text-center pos-rel">
                          <div className="testi-content pos-rel">
                            <div className="testi-bg-icon">
                              <img
                                src="img/testimonials/testi-box-bg.png"
                                alt=""
                              />
                            </div>
                            <div className="testi-quato-icon">
                              <img
                                src="img/testimonials/testi-quato-icon.png"
                                alt=""
                              />
                            </div>
                            <div className="text-text-boxx">
                              <p>
                              “I currently work for McMillan healthcare on a placement in Surrey, they found me work that was close to home and I have regular block bookings. The pay rates are very fair and I enjoy working with them”
                              </p>
                            </div>
                            {/* <span></span> */}
                          </div>
                          {/* <div className="testi-author">
                            <h2 className="testi-author-title">
                              Rosalina D. Williamson
                            </h2>
                            <span className="testi-author-desination">
                              founder, uithemes
                            </span>
                          </div>
                          <div className="test-author-icon">
                            <img
                              src="img/testimonials/testi-author-icon.png"
                              alt=""
                            />
                          </div> */}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div>
              <div className="testimonials-area ">
                <div className="container">
                  <div className="row">
                    <div className="col-xl-6 offset-xl-3 col-lg-8 offset-lg-2 col-md-10 offset-md-1"></div>
                  </div>
                  <div className="single-testi">
                    <div className="row">
                      <div className="col-xl-10 offset-xl-1 col-lg-12 col-md-12">
                        <div className="testi-box text-center pos-rel">
                          <div className="testi-content pos-rel">
                            <div className="testi-bg-icon">
                              <img
                                src="img/testimonials/testi-box-bg.png"
                                alt=""
                              />
                            </div>
                            <div className="testi-quato-icon">
                              <img
                                src="img/testimonials/testi-quato-icon.png"
                                alt=""
                              />
                            </div>
                            <div className="text-text-boxx">
                              <p>
                              “I was recommended to McMillan Healthcare through a friend and I am glad I contacted them as they have been very helpful and always reach out to me with any work opportunities!  They are great”
                              </p>
                            </div>
                            {/* <span></span> */}
                          </div>
                          {/* <div className="testi-author">
                            <h2 className="testi-author-title">
                              Rosalina D. Williamson
                            </h2>
                            <span className="testi-author-desination">
                              founder, uithemes
                            </span>
                          </div>
                          <div className="test-author-icon">
                            <img
                              src="img/testimonials/testi-author-icon.png"
                              alt=""
                            />
                          </div> */}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            {/* <div>
              <h3>5</h3>
            </div>
            <div>
              <h3>6</h3>
            </div>
            <div>
              <h3>7</h3>
            </div>
            <div>
              <h3>8</h3>
            </div>
            <div>
              <h3>9</h3>
            </div> */}
          </Slider>
        </div>
      </div>
    </>
  );
};

export default AboutTestimonial;
